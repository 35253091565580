import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { DOWNLOAD_BRV_REPORT, DOWNLOAD_JV_REPORT, GET_BRV_REPORTS, GET_JV_REPORTS, GET_SMC_PAYMENT_GATEWAY, UPDATE_VOUCHER_NUMBER, FETCH_VOUCHER_LIST, VIEW_VOUCHER, SUBMIT_VOUCHER_STATUS, ASSIGN_VOUCHER_DETAILS, FETCH_ACCOUNTING_REPORTS, DOWNLOAD_ACCOUNTING_REPORTS, FETCH_MONTHLY_REPORTS, DOWNLOAD_MONTHLY_REPORTS } from 'actions/smcGateway';

function* getSMCGateway(action) {
  try {
    const response = yield call(API.getSMCGateway, action.payload);
    yield put({ type: GET_SMC_PAYMENT_GATEWAY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_SMC_PAYMENT_GATEWAY.FAIL, payload: { error: e } });
  }
}

function* getBRVReports(action) {
  try {
    const response = yield call(API.getBRVReports, action.payload);
    yield put({ type: GET_BRV_REPORTS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_BRV_REPORTS.FAIL, payload: { error: e } });
  }
}

function* getJVReports(action) {
  try {
    const response = yield call(API.getJVReports, action.payload);
    yield put({ type: GET_JV_REPORTS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_JV_REPORTS.FAIL, payload: { error: e } });
  }
}

function* downloadBRVReport(action) {
  try {
    const response = yield call(API.downloadBRVReport, action.payload);
    yield put({ type: DOWNLOAD_BRV_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_BRV_REPORT.FAIL, payload: { error: e } });
  }
}

function* downloadJVReport(action) {
  try {
    const response = yield call(API.downloadJVReport, action.payload);
    yield put({ type: DOWNLOAD_JV_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_JV_REPORT.FAIL, payload: { error: e } });
  }
}

function* assignVoucherNumber(action) {
  try {
    const response = yield call(API.assignVoucherNumber, action.payload);
    yield put({ type: UPDATE_VOUCHER_NUMBER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_VOUCHER_NUMBER.FAIL, payload: { error: e } });
  }
}



//-----------------------------------------------------------------------------------NEW



function* fetchVoucherList(action) {
  try {
    const response = yield call(API.fetchVoucherList, action.payload);
    yield put({ type: FETCH_VOUCHER_LIST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_VOUCHER_LIST.FAIL, payload: { error: e } });
  }
}

function* viewVoucher(action) {
  try {
    const response = yield call(API.viewVoucher, action.payload);
    yield put({ type: VIEW_VOUCHER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: VIEW_VOUCHER.FAIL, payload: { error: e } });
  }
}

function* submitVoucherStatus(action) {
  try {
    const response = yield call(API.submitVoucherStatus, action.payload);
    yield put({ type: SUBMIT_VOUCHER_STATUS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: SUBMIT_VOUCHER_STATUS.FAIL, payload: { error: e } });
  }
}

function* assignVoucherDetails(action) {
  try {
    const response = yield call(API.assignVoucherDetails, action.payload);
    yield put({ type: ASSIGN_VOUCHER_DETAILS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: ASSIGN_VOUCHER_DETAILS.FAIL, payload: { error: e } });
  }
}


function* fetchAccountingReports(action) {
  try {
    const response = yield call(API.fetchAccountingReports, action.payload);
    yield put({ type: FETCH_ACCOUNTING_REPORTS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_ACCOUNTING_REPORTS.FAIL, payload: { error: e } });
  }
}


function* downloadAccountingReports(action) {
  try {
    const response = yield call(API.downloadAccountingReports, action.payload);
    yield put({ type: DOWNLOAD_ACCOUNTING_REPORTS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_ACCOUNTING_REPORTS.FAIL, payload: { error: e } });
  }
}



function* fetchMonthlyReports(action) {
  try {
    const response = yield call(API.fetchMonthlyReports, action.payload);
    yield put({ type: FETCH_MONTHLY_REPORTS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_MONTHLY_REPORTS.FAIL, payload: { error: e } });
  }
}

function* downloadMonthlyReports(action) {
  try {
    const response = yield call(API.downloadMonthlyReports, action.payload);
    yield put({ type: DOWNLOAD_MONTHLY_REPORTS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_MONTHLY_REPORTS.FAIL, payload: { error: e } });
  }
}



function* smcGateway() {
  yield takeLatest(GET_SMC_PAYMENT_GATEWAY.REQUEST, getSMCGateway);
  yield takeLatest(GET_BRV_REPORTS.REQUEST, getBRVReports);
  yield takeLatest(GET_JV_REPORTS.REQUEST, getJVReports);
  yield takeLatest(DOWNLOAD_BRV_REPORT.REQUEST, downloadBRVReport);
  yield takeLatest(DOWNLOAD_JV_REPORT.REQUEST, downloadJVReport);
  yield takeLatest(UPDATE_VOUCHER_NUMBER.REQUEST, assignVoucherNumber);


  //--------------------------------------------------

  yield takeLatest(FETCH_VOUCHER_LIST.REQUEST, fetchVoucherList);
  yield takeLatest(VIEW_VOUCHER.REQUEST, viewVoucher);
  yield takeLatest(SUBMIT_VOUCHER_STATUS.REQUEST, submitVoucherStatus);
  yield takeLatest(ASSIGN_VOUCHER_DETAILS.REQUEST, assignVoucherDetails);
  yield takeLatest(FETCH_ACCOUNTING_REPORTS.REQUEST, fetchAccountingReports);
  yield takeLatest(DOWNLOAD_ACCOUNTING_REPORTS.REQUEST, downloadAccountingReports);
  yield takeLatest(FETCH_MONTHLY_REPORTS.REQUEST, fetchMonthlyReports);
  yield takeLatest(DOWNLOAD_MONTHLY_REPORTS.REQUEST, downloadMonthlyReports);

}

export default smcGateway;
