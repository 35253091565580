import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useCallback, useEffect, useState } from 'react';
import Card from 'components/inputs/Card';
import { FETCH_FAULTY_CUSTOMER_TRANSACTION, DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION, DOWNLOAD_PAYMENT_RECEIPT } from 'actions/customer';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { useParams } from 'react-router-dom';
import { BiSort } from 'react-icons/bi';
import { roundOfDigit } from 'components/common/utils';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { SiGooglesheets } from 'react-icons/si';
import fileDownload from 'js-file-download';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

const CustomerFaultyTransaction = () => {
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const { t } = useTranslation();
  const customerTransactionList = useSelector((state) => state.customerTransaction.customerTransaction);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);

  const getCustomerTransaction = useCallback(
    (data) => {
      const transactionData = {
        ...data,
        id: customerId,
      };
      dispatch({ type: FETCH_FAULTY_CUSTOMER_TRANSACTION.REQUEST, payload: transactionData });
    },
    [customerId]
  );

  const downloadpaymentReceipt = useCallback(
    (data) => {
      const walletId = {
        id: data.id, // Pass the id from the function parameter
      };
      dispatch({
        type: DOWNLOAD_PAYMENT_RECEIPT.REQUEST,
        payload: walletId,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Payment_Receipt'}.pdf`);
            }
          }
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    getCustomerTransaction();
  }, []);

  const downloadCustomerTransactions = useCallback((data = {}) => {
    const customerTransactionsData = {
      id: customerId,
      excel: true,
      ...data,
    };
    dispatch({
      type: DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION.REQUEST,
      payload: customerTransactionsData,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Customer Transactions'}.xlsx`);
          }
        }
      },
    });
  }, []);

  const downloadFile = () => {
    downloadCustomerTransactions();
  };

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getCustomerTransaction(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getCustomerTransaction(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <Card className="customerDetail-table">
            <Row className="customerBooking-table-heading-row">
              <Col xl={11} lg={11} md={11}>
                <div className="customerDetail-table-heading">
                  {isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.title')}
                </div>
              </Col>
              <Col xl={1} lg={1} md={1}>
                {isLoading ? (
                  <div className="loader--block">
                    <Skeleton height={15} width={100} />
                  </div>
                ) : (
                  <div className="customerBooking-excel-icon" onClick={downloadFile}>
                    {isLoading ? <Skeleton height={30} width={30} circle="true" /> : <SiGooglesheets size={35} color={'#5dad5d'} />}
                  </div>
                )}
              </Col>
            </Row>
            <div className="customerDetail-list-table">
              <div className="table-responsive">
                <table className="record-list-table">
                  <thead>
                    <tr>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.srNo')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.paymentType')}</span>
                          <span className="ico" onClick={() => handleSorting('type', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.reason')}</span>
                          <span className="ico" onClick={() => handleSorting('wallet_type', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.faulty')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.bookingID')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.previousBalance')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.transactionAmount')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.updatedBalance')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.dateTime')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('customerTransaction.action')}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.isEmpty(customerTransactionList) ? (
                      <tr>
                        <td colSpan={11} className="border-0">
                          <div className="empty-data-block">{t('customerTransaction.noData')}</div>
                        </td>
                      </tr>
                    ) : (
                      (() => {
                        let runningBalance = 0; // Start with a balance of 0 for the first transaction

                        // Reverse the list for calculation but display from bottom to top
                        const transactionsReversed = [...customerTransactionList].reverse();

                        return transactionsReversed
                          .map((item, index) => {
                            const transactionType = _.get(item, 'type', ''); // credit or debit
                            const transactionAmount = _.get(item, 'amount', 0);

                            // Calculate updated balance based on the transaction type
                            const updatedBalance =
                              transactionType === 'credit' ? runningBalance + transactionAmount : runningBalance - transactionAmount;

                            // Store the previous balance for this transaction (i.e., running balance before this transaction)
                            const previousBalance = runningBalance;

                            // Update running balance for the next transaction (the one above in the UI)
                            runningBalance = updatedBalance;

                            // Now render the rows from the bottom to the top
                            return (
                              <tr key={`transaction-data-item-${index}`}>
                                <td>{isLoading ? <Skeleton height={15} width={100} /> : transactionsReversed.length - index}</td>{' '}
                                {/* Row numbering in reverse */}
                                <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'type', '-')}</td>
                                <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'wallet_type', '-')}</td>
                                <td>
                                  {isLoading ? (
                                    <Skeleton height={15} width={100} />
                                  ) : _.get(item, 'wallet_type') === 'WalletTopup' ? (
                                    _.get(item, 'deleted') ? (
                                      'Fail'
                                    ) : (
                                      'Paid'
                                    ) // Show 'true' or 'false' based on the 'deleted' field
                                  ) : (
                                    '-' // If not 'WalletTopup', show '-'
                                  )}
                                </td>
                                <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'booking.id', '-')}</td>
                                <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(previousBalance, 2)}</td>{' '}
                                {/* Previous Balance */}
                                <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(transactionAmount, 2)}</td>{' '}
                                {/* Transaction Amount */}
                                <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(updatedBalance, 2)}</td> {/* Updated Balance */}
                                <td>
                                  {isLoading ? (
                                    <Skeleton height={15} width={100} />
                                  ) : item.createdAt ? (
                                    moment(item.createdAt).utc(true).format('DD/MM/YYYY  H:mm:ss')
                                  ) : (
                                    '-'
                                  )}
                                </td>
                                <td>
                                  {_.get(item, 'wallet_type') === 'WalletTopup' ? (
                                    <button className="btn btn-sm btn-primary" onClick={() => downloadpaymentReceipt({ id: item.id })}>
                                      {t('customerTransaction.downloadReceipt')}
                                    </button>
                                  ) : (
                                    '-'
                                  )}
                                </td>
                              </tr>
                            );
                          })
                          .reverse();
                      })()
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Card>
        </SkeletonTheme>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default CustomerFaultyTransaction;
