import React from 'react';
import PropTypes from 'prop-types';
import { FaCheck } from 'react-icons/fa';

const StepIndicator = ({ steps, currentStep }) => {
  return (
    <div className="step-indicator">
      <div className="step-indicator__container">
        <div className="step-indicator__steps">
          {steps.map((step, index) => (
            <div key={step.id} className="step-indicator__step">
              <div className="step-indicator__step-content">
                <span className="step-indicator__step-number">{index + 1}.</span>
                <span className="step-indicator__step-title">{step.title}</span>
              </div>

              <div className="step-indicator__progress">
                {index !== 0 && <div className={`step-indicator__line ${step.id <= currentStep ? 'active' : ''}`} />}
                <div className={`step-indicator__circle ${step.id <= currentStep ? 'active' : ''}`}>
                  {step.id < currentStep && <FaCheck size={12} />}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

StepIndicator.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default StepIndicator;
