import React, { useCallback } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { SEND_SAP_INVOICE_B2C_RECORDS_CGST_CGST, SEND_SAP_INVOICE_B2C_RECORDS_IGST } from 'actions/sap';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { toast } from 'react-toastify';
const SapPosting = ({ month }) => {
  const dispatch = useDispatch();

  const buttonStyle = {
    height: '64px',
    fontSize: '1.125rem',
    fontWeight: '500',
    backgroundColor: '#0a192f',
    borderColor: '#334155',
    color: 'white',
    width: '100%',
  };
  const sendSAPInvoiceB2CRecordsCGSTSGST = useCallback((data) => {
    dispatch({
      type: SEND_SAP_INVOICE_B2C_RECORDS_CGST_CGST.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          toast.success(res?.message);
        }
      },
    });
  }, []);
  const sendSAPInvoiceB2CRecordsIGST = useCallback((data) => {
    dispatch({
      type: SEND_SAP_INVOICE_B2C_RECORDS_IGST.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          toast.success(res?.data?.message);
        }
      },
    });
  }, []);

  return (
    <div style={{ padding: '24px' }}>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={10} lg={8}>
            <Row className="g-4">
              <Col xs={12} md={3}>
                <Button variant="outline-secondary" style={buttonStyle} onClick={() => sendSAPInvoiceB2CRecordsCGSTSGST({ month })}>
                  EAAS (SGST + CGST)
                </Button>
              </Col>
              <Col xs={12} md={3}>
                <Button variant="outline-secondary" style={buttonStyle} onClick={() => sendSAPInvoiceB2CRecordsIGST({ month })}>
                  EAAS (IGST)
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

SapPosting.propTypes = {
  month: PropTypes.string,
};

export default SapPosting;
