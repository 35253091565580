import { post, get, downloadFile } from 'utils/api';

export const sendSAPInvoiceB2CRecordsCGSTSGST = (data) =>{
  return post('/sapRoute/send-b2c-data-to-sap-cgst-sgst', data)
}
export const sendSAPInvoiceB2CRecordsIGST = (data) =>{
  return post('/sapRoute/send-b2c-data-to-sap-igst', data)
}

export const getSapMonthlyData = (data) =>{
  return get('/sapRoute/get-sap-data', data)
}
export const downloadInvoiceB2C = (data) =>{
  return downloadFile('/sapRoute/invoice-records-b2c', data)
}

export const sapFaultyBookingReport = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/charger_bookings/sapFaultyBookings?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/charger_bookings/sapFaultyBookings?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/charger_bookings/sapFaultyBookings?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/charger_bookings/sapFaultyBookings?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/charger_bookings/sapFaultyBookings?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/charger_bookings/sapFaultyBookings?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/charger_bookings/sapFaultyBookings?page=${parseInt(page)}`, data);
  }
};
export const sap = {
  sendSAPInvoiceB2CRecordsCGSTSGST,
  sendSAPInvoiceB2CRecordsIGST,
  getSapMonthlyData,
  downloadInvoiceB2C,
  sapFaultyBookingReport
};
