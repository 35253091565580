import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import fileDownload from 'js-file-download';
import * as _ from 'lodash';
import Button from 'components/inputs/Button';
import { useSelector, useDispatch } from 'react-redux';
import { DOWNLOAD_INVOICE_B2C } from 'actions/sap';
import PropTypes from 'prop-types';

const DataValidation = ({ month }) => {
  const dispatch = useDispatch();
  const isLoader = useSelector((state) => state.sapMonthlyData.isLoading);
  
  const { t } = useTranslation();

  const downloadInvoiceB2C = () => {
    dispatch({
      type: DOWNLOAD_INVOICE_B2C.REQUEST,
      payload: { month: month, excel: true, report: 'invoiceB2C' },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Invoice B2C'}.xlsx`);
          }
        }
      },
    });
  };

  return (
    <div className="space-y-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>
          {isLoader ? <Skeleton height={30} width={200} /> : `Month: ${month}`}
        </h3>
        <Button onClick={downloadInvoiceB2C} className="report-download-button">
          {isLoader ? <Skeleton height={15} width={100} /> : t('button.downloadExcel')}
        </Button>
      </div>
    </div>
  );
};

DataValidation.propTypes = {
  month: PropTypes.string.isRequired,
};

export default DataValidation;