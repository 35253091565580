import React, { useCallback, useEffect, useState, useContext } from 'react';
import Card from 'components/inputs/Card';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import DummyImage from 'assets/images/icons/dummyImage.png';
import Avatar from 'components/inputs/Avatar';
import CustomerProfile from './FleetProfile';
import DropDown from 'components/inputs/DropDown';
import { BsThreeDots } from 'react-icons/bs';
import { FETCH_CUSTOMER_PROFILE, GET_CUSTOMER, UPDATE_CUSTOMER, WALLET_TRANSACTION } from 'actions/customer';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import CustomerBooking from './FleetBooking';
import CustomerTransaction from './FleetTransaction';
import { AiOutlineCar } from 'react-icons/ai';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE, DELETE_STATUS_CODE } from 'components/common/constant';
import { Modal } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import { Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import * as Yup from 'yup';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import { toast } from 'react-toastify';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import RfidDetailsModal from './RfidDetailsModal';
import { FETCH_ROLE } from 'actions/role';
// import { CHANGE_ROLE } from 'actions/user';
// import { TENANT_ADMIN, ADMIN } from 'components/common/constant';
// import ChargeCoinTransaction from './ChargeCoinTransaction';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ToggleSwitch from 'components/inputs/ToggleSwitch';
import { UPDATE_VEHICLE, CREATE_MULTIPLE_VEHICLE, DELETE_VEHICLE } from 'actions/vehicle';
import DeleteModal from 'components/general/DeleteModal';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { FETCH_VEHICLE_MAKE } from 'actions/vehicleMake';
import { FETCH_VEHICLE_MODEL } from 'actions/vehicleModel';
import { GrMoreVertical } from 'react-icons/gr';
import MemberList from './MemberList';
import RfidList from './RFIDList';

const FleetDetail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { fleetId } = useParams();
  const { navigateTo } = useContext(NavContext);
  const [currentTab, setCurrentTab] = useState('bookings');
  // const allRoles = useSelector((state) => state.role.roles);
  const profileData = useSelector((state) => state.profile.userProfile);
  const [showWalletForm, setShowWalletForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditVehicleForm, setShowEditVehicleForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [vehicleId, setVehicleId] = useState('');
  const [showModal, setShowModal] = useState('');
  const isLoader = useSelector((state) => state.profile.isLoading);
  const customerProfileList = useSelector((state) => state.customerProfile.customerProfile);
  const isLoading = useSelector((state) => state.customerProfile.isLoading);
  const allVehicleMake = useSelector((state) => state.vehicleMake.vehicleMakes);
  const allVehicleModel = useSelector((state) => state.vehicleModel.vehicleModels);
  const makePage = useSelector((state) => state.vehicleMake.page);
  const modelPage = useSelector((state) => state.vehicleModel.page);
  const makeTotalPage = useSelector((state) => state.vehicleMake.totalPages);
  const modelTotalPage = useSelector((state) => state.vehicleModel.totalPages);
  // const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const currentUserName = _.get(profileData, 'name', '');
  const currentUserRole = _.get(profileData, 'role', '');
  const tenantId = _.get(profileData, 'tenant.id', '');
  const currentVehicle = vehicleId && customerProfileList?.vehicles.filter((item) => item.id === vehicleId);
  const getCustomerProfile = useCallback((id) => {
    dispatch({ type: FETCH_CUSTOMER_PROFILE.REQUEST, payload: id });
  }, []);

  const getAllRoles = useCallback((data) => {
    const roleData = {
      ...data,
      is_hidden: false,
    };
    dispatch({ type: FETCH_ROLE.REQUEST, payload: roleData });
  }, []);

  useEffect(() => {
    getAllVehicleMake({ limit: 999 });
    getAllRoles();
  }, [fleetId]);

  const updateCustomer = useCallback(
    (data) => {
      const customerData = {
        ...data,
        id: fleetId,
      };
      dispatch({
        type: UPDATE_CUSTOMER.REQUEST,
        payload: customerData,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            toast.success('Fleet updated successfully');
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            navigateTo(`/fleetProfile/${fleetId}`);
          }
        },
      });
    },
    [fleetId]
  );

  const addVehicle = useCallback(
    (data) => {
      dispatch({
        type: CREATE_MULTIPLE_VEHICLE.REQUEST,
        payload: data,
        cb: (res) => {
          if (_.get(res, 'status') === CREATE_STATUS_CODE) {
            toast.success('Vehicle added successfully');
            setTimeout(() => {
              window.location.reload();
              navigateTo(`/fleetProfile/${fleetId}`);
            }, 1000);
          }
        },
      });
    },
    [fleetId]
  );

  const editVehicleDetail = useCallback(
    (data) => {
      dispatch({
        type: UPDATE_VEHICLE.REQUEST,
        payload: { ...data },
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            setTimeout(() => {
              window.location.reload();
              navigateTo(`/fleetProfile/${fleetId}`);
            }, 1000);
          }
        },
      });
    },
    [fleetId]
  );

  const deleteVehicle = useCallback(
    () =>
      dispatch({
        type: DELETE_VEHICLE.REQUEST,
        payload: vehicleId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            setTimeout(() => {
              window.location.reload();
              navigateTo(`/fleetProfile/${fleetId}`);
            }, 1000);
          }
        },
      }),
    [vehicleId]
  );

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setVehicleId('');
  };

  const getAllVehicleMake = useCallback((data = {}) => {
    dispatch({ type: FETCH_VEHICLE_MAKE.REQUEST, payload: data });
  }, []);

  const getVehicleModelByMake = useCallback((data = {}) => {
    dispatch({ type: FETCH_VEHICLE_MODEL.REQUEST, payload: data });
  }, []);

  const suspendCustomer = useCallback(
    (data) => {
      const customerData = {
        ...data,
        id: fleetId,
      };
      dispatch({
        type: UPDATE_CUSTOMER.REQUEST,
        payload: customerData,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            // toast.success('Customer updated successfully');
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            navigateTo(`/fleetProfile/${fleetId}`);
          }
        },
      });
    },
    [fleetId]
  );

  const autoChargeCustomer = useCallback(
    (data) => {
      dispatch({
        type: UPDATE_VEHICLE.REQUEST,
        payload: data,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            navigateTo(`/fleetProfile/${fleetId}`);
          }
        },
      });
    },
    [fleetId]
  );

  const getCustomerDetail = useCallback((id) => {
    dispatch({ type: GET_CUSTOMER.REQUEST, payload: id });
  });

  const walletTransaction = useCallback(
    (data = {}) => {
      dispatch({
        type: WALLET_TRANSACTION.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === CREATE_STATUS_CODE) {
              toast.success('Transaction in wallet successfully');
              setShowWalletForm(false);
              navigateTo(`/fleetProfile/${fleetId}`);
            }
          }
        },
      });
    },
    [fleetId]
  );

  useEffect(() => {
    getCustomerProfile(fleetId);
  }, [fleetId]);

  const setTab = (e, tab) => {
    e.preventDefault();
    setCurrentTab(tab);
  };

  const handleClose = () => {
    setShowWalletForm(false);
    setShowEditForm(false);
    setShowAddForm(false);
    setShowEditVehicleForm(false);
    setShowModal(false);
  };

  const WalletTransactionSchema = Yup.object().shape({
    message: Yup.string().required(t('customerDetail.message')),
    type: Yup.string().required(t('customerDetail.type')),
    amount: Yup.number().required(t('customerDetail.amount')),
  });

  const CustomerSchema = Yup.object().shape({
    first_name: Yup.string().required(t('customerDetail.firstName')).max(100, t('customerDetail.maxlimit')),
    last_name: Yup.string().required(t('customerDetail.lastName')).max(100, t('customerDetail.maxlimit')),
  });

  const VehicleSchema = Yup.object().shape({
    // name: Yup.string().required('Vehicle name is required!'),
    type: Yup.string().required(t('vehicleForm.type')),
    make: Yup.string().required(t('vehicleForm.make')),
    model: Yup.string().required(t('vehicleForm.model')),
    rto_no: Yup.string().required(t('vehicleForm.rtoNo')),
    // .strict(true)
    // .trim(t('vehicleForm.space'))
    // .matches(/^[A-Za-z0-9\s]+$/, t('vehicleForm.matchNumAlpha'))
    // .max(100, t('vehicleForm.maxlimit')),
    // RFID: Yup.string().required(t('vehicleForm.rfid')),
    // .strict(true)
    // .trim(t('vehicleForm.space'))
    // // .matches(/^[A-Za-z0-9\s]+$/, t("vehicleForm.matchNumAlpha"))
    // .max(100, t('vehicleForm.maxlimit')),
  });

  const makeName = currentVehicle[0]?.make;
  const makeID = allVehicleMake.find((item) => item?.name === makeName)?.id;

  useEffect(() => {
    if (vehicleId && makeID) {
      getVehicleModelByMake({ makeId: makeID });
    }
  }, [makeID, vehicleId]);

  return (
    <React.Fragment>
      <LayoutContainer className="layout-container-main-page">
        <AdminHeader title={currentUserRole === 'fleet_owner' ? t('header.fleetProfile2') : t('header.fleetProfile')} backTo="/fleets" />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="customerDetail-page__main">
              <Card>
                <div className="page--info">
                  <div className="page--info--inner">
                    <div className="page-avatar">
                      {isLoader ? <Skeleton height="20" width="20" circle="true" /> : <Avatar imgSrc={DummyImage} name="userProfile" />}
                    </div>
                    <div className="customer-content">
                      <span className="customer-name">
                        {isLoading ? <Skeleton height="20" width="100" /> : _.get(customerProfileList, 'name', 'Guest User')}
                      </span>
                      <div className="customer-role">
                        <span className="customer-info">
                          {isLoading ? <Skeleton height="20" width="100" /> : _.startCase(_.get(customerProfileList, 'role', ''))}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* {(!_.isEmpty(manageCustomerPermissions) || (!_.isEmpty(getCustomerPermissions) && !_.isEmpty(manageCustomerPermissions))) && ( */}
                  <div className="customerDetail-dropdown_btn">
                    <DropDown
                      trigger={
                        <div className="dropdown-icon">
                          <BsThreeDots />
                        </div>
                      }
                      popupClass="bottom-left"
                    >
                      <ul>
                        {_.get(customerProfileList, 'role') === 'fleet_member' ? null : isLoading ? (
                          <Skeleton height="20" width="100" />
                        ) : _.get(customerProfileList, 'isActive') ? (
                          <li
                            className="item"
                            onClick={() => {
                              suspendCustomer({ isActive: false });
                            }}
                          >
                            {t('customerDetail.suspend')}
                          </li>
                        ) : (
                          <li
                            className="item"
                            onClick={() => {
                              suspendCustomer({ isActive: true });
                            }}
                          >
                            {t('customerDetail.active')}
                          </li>
                        )}
                        <li className="item" onClick={() => setShowEditForm(true)}>
                          {t('customerDetail.edit')}
                        </li>
                        <li className="item" onClick={() => setShowAddForm(true)}>
                          {t('customerDetail.addVehicle')}
                        </li>
                        {_.get(customerProfileList, 'role') === 'fleet_member' ? null : (
                          <li className="item" onClick={() => setShowWalletForm(true)}>
                            {t('customerDetail.updateWallet')}
                          </li>
                        )}
                        {_.get(customerProfileList, 'role') === 'fleet_owner' ? null : (
                          <li
                            className="item"
                            onClick={() => {
                              setShowModal(true);
                              getCustomerDetail(fleetId);
                            }}
                          >
                            {_.isEmpty(_.get(customerProfileList, 'rfid')) ? t('customerDetail.assignRFID') : t('customerDetail.manageRFID')}
                          </li>
                        )}
                      </ul>
                    </DropDown>
                  </div>
                  {/* )} */}
                </div>
                <div>
                  <div className="tab-block">
                    <div className="tab-nav-block">
                      <a
                        href="#"
                        className={`tab-nav__item ${currentTab === 'profile' ? 'active' : ''}`}
                        onClick={(e) => setTab(e, 'profile')}
                        data-content="tab-profile"
                      >
                        {isLoading ? <Skeleton height="20" width="100" /> : t('customerDetail.tabProfile')}
                      </a>
                      <a
                        href="#"
                        className={`tab-nav__item ${currentTab === 'bookings' ? 'active' : ''}`}
                        onClick={(e) => setTab(e, 'bookings')}
                        data-content="tab-bookings"
                      >
                        {isLoading ? <Skeleton height="20" width="100" /> : t('customerDetail.tabBookings')}
                      </a>
                      {currentUserRole === 'fleet_owner' ? null : (
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'transactions' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'transactions')}
                          data-content="tab-transactions"
                        >
                          {isLoading ? <Skeleton height="20" width="100" /> : t('customerDetail.tabTransactions')}
                        </a>
                      )}
                      {currentUserRole === 'fleet_owner' ? null : (
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'member' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'member')}
                          data-content="tab-transactions"
                        >
                          {isLoading ? <Skeleton height="20" width="100" /> : t('customerDetail.tabFleetMembers')}
                        </a>
                      )}
                      {currentUserRole === 'fleet_owner' ? null : (
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'RFID' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'RFID')}
                          data-content="tab-transactions"
                        >
                          {isLoading ? <Skeleton height="20" width="100" /> : t('customerDetail.tabRFID')}
                        </a>
                      )}
                    </div>
                    {currentTab === 'profile' && (
                      <div className="tab-content-block">
                        <div className="tab-content__item active" id="charger-details-tab">
                          {currentTab === 'profile' && <CustomerProfile />}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Card>
              {currentTab === 'profile' && (
                <Card className="customerDetail-table">
                  <div className="customerDetail-table-heading">
                    {' '}
                    {isLoader ? <Skeleton height="20" width="100" /> : t('customerDetail.vehicleDetails')}
                  </div>
                  <div className="customerDetail-list-table">
                    <div className="table-responsive">
                      <table className="record-list-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height="20" width="100" /> : t('customerDetail.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height="20" width="100" /> : t('customerDetail.vehicleName')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height="20" width="100" /> : t('customerDetail.vehicleRegistrationNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height="20" width="100" /> : t('customerDetail.sessions')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height="20" width="100" /> : t('customerDetail.evccId')}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {_.isEmpty(_.get(customerProfileList, 'vehicles')) ? (
                            <tr>
                              <td colSpan={8} className="border-0">
                                <div className="empty-data-block">{t('customerDetail.noData')}</div>
                              </td>
                            </tr>
                          ) : (
                            _.map(_.get(customerProfileList, 'vehicles'), (vehicle, index) => {
                              return (
                                <tr key={`vehicle-data-item-${index}`}>
                                  <td>{isLoader ? <Skeleton height="20" width="100" /> : index + 1}</td>
                                  <td>
                                    <span className="item-icon">
                                      {isLoader ? <Skeleton height="20" width="20" circle="true" /> : <AiOutlineCar />}
                                    </span>
                                    <span>
                                      {isLoader ? <Skeleton height="20" width="100" /> : _.get(vehicle, 'make', '')} {_.get(vehicle, 'model', '')}
                                    </span>
                                  </td>
                                  <td>{isLoader ? <Skeleton height="20" width="100" /> : _.get(vehicle, 'rto_no', '-')}</td>
                                  <td>{isLoader ? <Skeleton height="20" width="100" /> : _.get(vehicle, 'no_of_session', '-')}</td>
                                  <td>{isLoader ? <Skeleton height="20" width="100" /> : _.get(vehicle, 'evcc_id', '-')}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height="20" width="100" />
                                    ) : (
                                      <ToggleSwitch
                                        checked={_.get(vehicle, 'is_autocharge_enabled')}
                                        onChange={(e) => {
                                          const isCheck = e.target.checked;
                                          const id = _.get(vehicle, 'id');
                                          const customerData = _.omit(vehicle, ['is_default', 'year', 'unit_consumed', 'no_of_session']);
                                          if (isCheck) {
                                            autoChargeCustomer({ ...customerData, is_autocharge_enabled: true, id });
                                          } else {
                                            autoChargeCustomer({ ...customerData, is_autocharge_enabled: false, id });
                                          }
                                        }}
                                      />
                                    )}
                                  </td>
                                  <td>
                                    <div className="rfid-group">
                                      <span className="list-icon">
                                        {isLoader ? (
                                          <Skeleton height={15} width={100} />
                                        ) : (
                                          <DropDown
                                            trigger={
                                              <span className="dropdown-icon">
                                                <GrMoreVertical />
                                              </span>
                                            }
                                            popupClass="bottom-left"
                                          >
                                            <ul>
                                              <li
                                                className="item"
                                                onClick={() => {
                                                  setShowEditVehicleForm(true);
                                                  setVehicleId(_.get(vehicle, 'id', ''));
                                                }}
                                              >
                                                {t('customerDetail.editVehicle')}
                                              </li>

                                              <li
                                                className="item"
                                                onClick={() => {
                                                  setShowDeleteModal(true);
                                                  setVehicleId(_.get(vehicle, 'id', ''));
                                                }}
                                              >
                                                {t('customerDetail.deleteVehicle')}
                                              </li>
                                            </ul>
                                          </DropDown>
                                        )}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Card>
              )}
              {currentTab === 'bookings' && <CustomerBooking />}
              {currentTab === 'transactions' && <CustomerTransaction />}
              {currentTab === 'member' && <MemberList />}
              {currentTab === 'RFID' && <RfidList />}
            </div>
          </div>
        </SkeletonTheme>
        {showWalletForm && (
          <Modal show={showWalletForm} size="lg" centered>
            <div className="wallet-transaction_main">
              <Card>
                <div className="wallet-transaction-header__block">
                  <div className="wallet-transaction-name">
                    <span>{t('customerDetail.walletTransaction')}</span>
                  </div>
                  <div className="close-btn">
                    <MdClose size={30} color="#be210b" onClick={handleClose} />
                  </div>
                </div>
                <div className="wallet-transaction_form">
                  <Formik
                    initialValues={{
                      amount: '',
                      user: _.get(customerProfileList, 'id', ''),
                      type: '',
                      message: '',
                    }}
                    validationSchema={WalletTransactionSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      walletTransaction(values);
                      setSubmitting(false);
                    }}
                  >
                    {({ values, errors, touched, setFieldValue, handleSubmit, handleChange, isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col lg={6}>
                            <TextInput
                              isRequired
                              label={t('customerDetail.walletAmount')}
                              type="number"
                              placeholder={t('customerDetail.walletAmount')}
                              name="amount"
                              value={_.get(values, 'amount')}
                              onChange={handleChange}
                              error={errors.amount && touched.amount ? errors.amount : null}
                            />
                          </Col>
                          <Col lg={6}>
                            <Select
                              isRequired
                              label={t('customerDetail.walletType')}
                              options={[
                                { label: 'Credit', value: 'credit' },
                                { label: 'Debit', value: 'debit' },
                              ]}
                              placeholder={t('placeHolder.selectType')}
                              name="type"
                              value={values.type}
                              onChange={(val) => setFieldValue(`type`, val)}
                              error={errors.type && touched.type ? errors.type : null}
                            />
                          </Col>
                          <Col lg={12}>
                            <TextInput
                              isRequired
                              as="textarea"
                              rows="4"
                              inputClass="scrollable"
                              placeholder={t('customerDetail.walletMessage')}
                              label={t('customerDetail.walletMessage')}
                              name="message"
                              value={values.message}
                              onChange={handleChange}
                              error={errors.message && touched.message ? errors.message : null}
                            />
                          </Col>
                        </Row>
                        <div className="wallet-transaction_button">
                          <Button className="wallet-transaction-btn cancel_btn" onClick={handleClose}>
                            {t('button.cancel')}
                          </Button>
                          <Button type="submit" disabled={isSubmitting} className="wallet-transaction-btn create-btn">
                            {t('button.submit')}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Card>
            </div>
          </Modal>
        )}
        {showEditForm && (
          <Modal show={showEditForm} size="lg" centered>
            <div className="customer-form-page__main">
              <Card>
                <div className="customer-form-header__block">
                  <div className="customer-header-name">
                    <span>{t('customerDetail.editCustomer')}</span>
                  </div>
                  <div className="close___btn">
                    <MdClose size={30} color="#BE210B" onClick={handleClose} />
                  </div>
                </div>
                <div className="customer-form-body__block">
                  <div className="customer-form--block">
                    <Formik
                      initialValues={{
                        name: '',
                        first_name: _.get(customerProfileList, 'first_name'),
                        last_name: _.get(customerProfileList, 'last_name'),
                        phone: `+${_.get(customerProfileList, 'country_code')} ${_.get(customerProfileList, 'phone')}`.toString(),
                        email: _.get(customerProfileList, 'email'),
                        sap_customer_id: _.get(customerProfileList, 'sap_customer_id'),
                      }}
                      validationSchema={CustomerSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        const updateCustomerData = _.omit(values, ['phone']);
                        updateCustomer({
                          ...updateCustomerData,
                          name: values.first_name + ' ' + values.last_name,
                        });
                        setSubmitting(false);
                      }}
                    >
                      {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>

                          
                          <Row>
                            <Col xl={6} lg={12}>
                              <TextInput
                                isRequired
                                label={t('customerDetail.editFirstName')}
                                placeholder={t('customerDetail.editFirstName')}
                                name="first_name"
                                value={values.first_name}
                                onChange={handleChange}
                                error={errors.first_name && touched.first_name ? errors.first_name : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <TextInput
                                isRequired
                                label={t('customerDetail.editLastName')}
                                placeholder={t('customerDetail.editLastName')}
                                name="last_name"
                                value={values.last_name}
                                onChange={handleChange}
                                error={errors.last_name && touched.last_name ? errors.last_name : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <PhoneNumberInput
                                disabled
                                label={t('customerDetail.editPhoneNumber')}
                                name="phoneNumber"
                                value={values.phone}
                                onChange={(val) => {
                                  setFieldValue(`phone`, val);
                                }}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <TextInput
                                label={t('customerDetail.editEmail')}
                                placeholder={t('customerDetail.editEmail')}
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <TextInput
                                label={t('customerDetail.sapCustomerId')}
                                placeholder={t('customerDetail.sapCustomerId')}
                                name="sap_customer_id"
                                value={values.sap_customer_id}
                                onChange={handleChange}
                              />
                            </Col>
                          </Row>
                          <div className="form-btn__block">
                            <Button className="form_btn cancel____btn" onClick={handleClose}>
                              {t('button.cancel')}
                            </Button>
                            <Button type="submit" disabled={isSubmitting} className="form_btn">
                              {t('button.edit')}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Card>
            </div>
          </Modal>
        )}

        {showAddForm && (
          <Modal show={showAddForm} size="lg" centered>
            <div className="customer-form-page__main">
              <Card>
                <div className="customer-form-header__block">
                  <div className="customer-header-name">
                    <span>{t('customerDetail.addVehicle')}</span>
                  </div>
                  <div className="close___btn">
                    <MdClose size={30} color="#BE210B" onClick={handleClose} />
                  </div>
                </div>
                <div className="customer-form-body__block">
                  <div className="customer-form--block">
                    <Formik
                      initialValues={{
                        vin_num: '',
                        // name: '',
                        type: '',
                        make: '',
                        model: '',
                        charging_station: '',
                        rto_no: '',
                        // RFID: '',
                        max_charging_capacity: '',
                        // insurance_expiry: moment(),
                        // owner: '',
                        documents: [],
                        evcatalog: '',
                        ownership_type: 'b2b',
                        // picture: 'http://jbsdjcj.png',
                      }}
                      validationSchema={VehicleSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        //add tenant api call...
                        const selectedVehicle = _.find(allVehicleModel, { model: values.model });
                        const formData = [];
                        formData.push({
                          ...values,
                          max_charging_capacity: selectedVehicle.max_charging_capacity.toString(),
                          owner: fleetId,
                          tenant: tenantId,
                        });
                        addVehicle(formData);
                        setSubmitting(false);
                      }}
                    >
                      {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => (
                        <Form onSubmit={handleSubmit}>
                          <div className="vehicle-form__inner">
                            <Row>
                              <Col lg={6} md={12}>
                                <Select
                                  isRequired
                                  label={t('addVehicleForm.vehicleType')}
                                  placeholder={t('placeHolder.selectVehicleType')}
                                  options={[
                                    { label: 'Bus', value: 'bus' },
                                    { label: 'Car', value: 'car' },
                                    { label: 'Two wheeler', value: 'two wheeler' },
                                    { label: 'Three wheeler', value: 'three wheeler' },
                                    { label: 'Other', value: 'other' },
                                  ]}
                                  name="type"
                                  value={values.type}
                                  onChange={(val) => setFieldValue('type', val)}
                                  error={errors.type && touched.type ? errors.type : null}
                                />
                              </Col>
                              <Col lg={6} md={12}>
                                <Select
                                  isRequired
                                  className="mb-4"
                                  label={t('addVehicleForm.vehicleMake')}
                                  options={_.map(allVehicleMake, (item) => {
                                    return { label: item.name, value: item.name };
                                  })}
                                  placeholder={t('placeHolder.selectMake')}
                                  value={values.make}
                                  name="make"
                                  onMenuScrollDown={true}
                                  getDataOnScrollDown={getAllVehicleMake}
                                  page={makePage}
                                  totalPage={makeTotalPage}
                                  onChange={(val) => {
                                    setFieldValue(`make`, val);
                                    const currentMake = _.find(allVehicleMake, { name: val });
                                    getVehicleModelByMake({ makeId: currentMake.id });
                                  }}
                                  error={errors.make && touched.make ? errors.make : null}
                                />
                              </Col>
                              <Col lg={6} md={12}>
                                <Select
                                  isRequired
                                  className="mb-4"
                                  placeholder={t('placeHolder.selectModel')}
                                  options={_.map(allVehicleModel, (item) => {
                                    return { label: item.model, value: _.find(allVehicleModel, { id: item.id }).model };
                                  })}
                                  label={t('addVehicleForm.vehicleModel')}
                                  name="model"
                                  value={values.model}
                                  onMenuScrollDown={true}
                                  getDataOnScrollDown={(data) => getVehicleModelByMake({ ...data, makeId: values.make })}
                                  page={modelPage}
                                  totalPage={modelTotalPage}
                                  onChange={(val) => {
                                    const currentModel = _.find(allVehicleModel, { model: val });
                                    setFieldValue(`model`, _.get(currentModel, 'model', ''));
                                    setFieldValue(`evcatalog`, _.get(currentModel, 'id', ''));
                                  }}
                                  error={errors.model && touched.model ? errors.model : null}
                                />
                              </Col>
                              <Col lg={6} md={12}>
                                <TextInput
                                  isRequired
                                  placeholder={t('placeHolder.vehicleRegistrationNo')}
                                  label={t('addVehicleForm.vehicleRegistrationNo')}
                                  name="rto_no"
                                  value={values.rto_no}
                                  onChange={handleChange}
                                  error={errors.rto_no && touched.rto_no ? errors.rto_no : null}
                                />
                              </Col>
                              {/* <Col lg={6} md={12}>
                                <TextInput
                                  isRequired
                                  placeholder={t('placeHolder.rfid/ID Tag')}
                                  label={t('addVehicleForm.rfid/IDTag')}
                                  name="RFID"
                                  value={values.RFID}
                                  onChange={handleChange}
                                  error={errors.RFID && touched.RFID ? errors.RFID : null}
                                />
                              </Col> */}
                              <Col lg={6} md={12}>
                                <TextInput
                                  label={t('addVehicleForm.vinNumber')}
                                  placeholder={t('placeHolder.vinNumber')}
                                  name="vin_num"
                                  value={values.vin_num}
                                  onChange={handleChange}
                                />
                              </Col>
                            </Row>
                          </div>
                          <div className="vehicle-save--btn--block">
                            <Button type="submit" className="vehicle-save-btn save--btn">
                              {t('customerDetail.addVehicle')}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Card>
            </div>
          </Modal>
        )}

        {showEditVehicleForm && (
          <Modal show={showEditVehicleForm} size="lg" centered>
            <div className="customer-form-page__main">
              <Card>
                <div className="customer-form-header__block">
                  <div className="customer-header-name">
                    <span>{t('customerDetail.editVehicle')}</span>
                  </div>
                  <div className="close___btn">
                    <MdClose size={30} color="#BE210B" onClick={handleClose} />
                  </div>
                </div>
                <div className="customer-form-body__block">
                  <div className="customer-form--block">
                    <Formik
                      initialValues={{
                        owner: _.map(currentVehicle, (vehicle) => vehicle && vehicle.owner).toString(),
                        ownership_type: _.map(currentVehicle, (vehicle) => vehicle && vehicle.ownership_type).toString(),
                        RFID: _.map(currentVehicle, (vehicle) => vehicle && vehicle.RFID).toString(),
                        vin_num: _.map(currentVehicle, (vehicle) => vehicle && vehicle.vin_num).toString(),
                        type: _.map(currentVehicle, (vehicle) => vehicle && vehicle.type).toString(),
                        make: _.map(currentVehicle, (vehicle) => vehicle && vehicle.make).toString(),
                        model: _.map(currentVehicle, (vehicle) => vehicle && vehicle.model).toString(),
                        rto_no: _.map(currentVehicle, (vehicle) => vehicle && vehicle.rto_no).toString(),
                      }}
                      validationSchema={VehicleSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        // edit tenant api call....
                        const editVehicleData = _.omit(values, ['is_default', 'unit_consumed']);
                        const selectedVehicle = _.find(allVehicleModel, { model: values.model });
                        editVehicleDetail({
                          ...editVehicleData,
                          // delete below tenant, when vehicle-data have the tenant-data
                          tenant: values.tenant ? values.tenant : tenantId,
                          max_charging_capacity: selectedVehicle.max_charging_capacity.toString(),
                          id: vehicleId,
                        });
                        setSubmitting(false);
                      }}
                    >
                      {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => (
                        <Form onSubmit={handleSubmit}>
                          <div className="vehicle-form__inner">
                            <Row>
                              <Col lg={6} md={12}>
                                <Select
                                  isRequired
                                  label={t('addVehicleForm.vehicleType')}
                                  placeholder={t('placeHolder.selectVehicleType')}
                                  options={[
                                    { label: 'Bus', value: 'bus' },
                                    { label: 'Car', value: 'car' },
                                    { label: 'Two wheeler', value: 'two wheeler' },
                                    { label: 'Three wheeler', value: 'three wheeler' },
                                    { label: 'Other', value: 'other' },
                                  ]}
                                  name="type"
                                  value={values.type}
                                  onChange={(val) => setFieldValue('type', val)}
                                  error={errors.type && touched.type ? errors.type : null}
                                />
                              </Col>
                              <Col lg={6} md={12}>
                                <Select
                                  isRequired
                                  className="mb-4"
                                  label={t('addVehicleForm.vehicleMake')}
                                  options={_.map(allVehicleMake, (item) => {
                                    return { label: item.name, value: item.name };
                                  })}
                                  placeholder={t('placeHolder.selectMake')}
                                  value={values.make}
                                  name="make"
                                  onMenuScrollDown={true}
                                  getDataOnScrollDown={getAllVehicleMake}
                                  page={makePage}
                                  totalPage={makeTotalPage}
                                  onChange={(val) => {
                                    setFieldValue(`make`, val);
                                    const currentMake = _.find(allVehicleMake, { name: val });
                                    getVehicleModelByMake({ makeId: currentMake.id });
                                  }}
                                  error={errors.make && touched.make ? errors.make : null}
                                />
                              </Col>
                              <Col lg={6} md={12}>
                                <Select
                                  isRequired
                                  className="mb-4"
                                  placeholder={t('placeHolder.selectModel')}
                                  options={_.map(allVehicleModel, (item) => {
                                    return { label: item.model, value: _.find(allVehicleModel, { id: item.id }).model };
                                  })}
                                  label={t('addVehicleForm.vehicleModel')}
                                  name="model"
                                  value={values.model}
                                  onMenuScrollDown={true}
                                  getDataOnScrollDown={(data) => getVehicleModelByMake({ ...data, makeId: values.make })}
                                  page={modelPage}
                                  totalPage={modelTotalPage}
                                  onChange={(val) => {
                                    const currentModel = _.find(allVehicleModel, { model: val });
                                    setFieldValue(`model`, _.get(currentModel, 'model', ''));
                                    setFieldValue(`evcatalog`, _.get(currentModel, 'id', ''));
                                  }}
                                  error={errors.model && touched.model ? errors.model : null}
                                />
                              </Col>
                              <Col lg={6} md={12}>
                                <TextInput
                                  isRequired
                                  placeholder={t('placeHolder.vehicleRegistrationNo')}
                                  label={t('addVehicleForm.vehicleRegistrationNo')}
                                  name="rto_no"
                                  value={values.rto_no}
                                  onChange={handleChange}
                                  error={errors.rto_no && touched.rto_no ? errors.rto_no : null}
                                />
                              </Col>
                              {/* <Col lg={6} md={12}>
                                <TextInput
                                  isRequired
                                  placeholder={t('placeHolder.rfid/ID Tag')}
                                  label={t('addVehicleForm.rfid/IDTag')}
                                  name="RFID"
                                  value={values.RFID}
                                  onChange={handleChange}
                                  error={errors.RFID && touched.RFID ? errors.RFID : null}
                                />
                              </Col> */}
                              <Col lg={6} md={12}>
                                <TextInput
                                  label={t('addVehicleForm.vinNumber')}
                                  placeholder={t('placeHolder.vinNumber')}
                                  name="vin_num"
                                  value={values.vin_num}
                                  onChange={handleChange}
                                />
                              </Col>
                            </Row>
                          </div>
                          <div className="vehicle-save--btn--block">
                            <Button type="submit" className="vehicle-save-btn save--btn">
                              {t('customerDetail.editVehicle')}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Card>
            </div>
          </Modal>
        )}

        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="vehicle" onClose={handleCloseDeleteModel} onRemove={deleteVehicle} />
          </Modal>
        )}

        {showModal && (
          <Modal show={showModal} size="lg" centered>
            <RfidDetailsModal onClose={handleClose} />
          </Modal>
        )}
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};
export default FleetDetail;
