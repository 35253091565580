import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { BiSort } from 'react-icons/bi';
import * as _ from 'lodash';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { FETCH_SAP_MONTHLY_DATA, SAP_FAULTY_BOOKING_REPORT } from 'actions/sap';
// import { FiEdit2 } from 'react-icons/fi';
import UpdateFaultyBooking from './faultybooking.modal';
import ReactPagination from 'components/general/ReactPagination';
import Button from 'components/inputs/Button';
import { BsDownload } from 'react-icons/bs';
import { DOWNLOAD_FAULTY_BOOKING_REPORT } from 'actions/dataReport';
import { getTimezoneFromCountryCode } from 'utils/timezone/timezoneUtils';
import fileDownload from 'js-file-download';

const DataCorrection = ({ setMonth, setShowButton, setIsSapFaultyBookingNull, setShowProgressBar, currentStep, setCurrentStep }) => {
  const dispatch = useDispatch();
  const sapMonthlyData = useSelector((state) => state.sapMonthlyData.sapMonthlyData);
  const isLoader = useSelector((state) => state.sapFaultyBookingReport.isLoading);
  const sapFaultyBookingList = useSelector((state) => state.sapFaultyBookingReport.sapFaultyBookingReport);
  const limit = useSelector((state) => state.sapFaultyBookingReport.limit);
  const page = useSelector((state) => state.sapFaultyBookingReport.page);
  const totalPages = useSelector((state) => state.sapFaultyBookingReport.totalPages);
  const totalData = useSelector((state) => state.sapFaultyBookingReport.total);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const [showModal, setShowModal] = useState(false);
  const [faultyBooking /*setFaultyBooking*/] = useState({});
  const [showFaultyBookingReport, setShowFaultyBookingReport] = useState(false);
  const [sortByItem, setSortByItem] = useState({ item: 'created_at ', order: 'asc' });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (currentStep === 1) {
      setShowProgressBar(false);
    } 
  }, [currentStep, setShowProgressBar]);

  const navigateToDataCorrection = (month) => {
    setCurrentStep(2)
    setShowProgressBar(true)
    setShowButton(true)
    setMonth(month);
  }

  const countryTimezone = getTimezoneFromCountryCode(_.get(profileData, 'country_code', 'UTC'));
  const faultyBookingReport = useCallback(
    (value) => {
      const data = {
        ...value,
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        isAllFaultyBookings: true,
        from: startDate,
        to: endDate,
      };
      dispatch({
        type: SAP_FAULTY_BOOKING_REPORT.REQUEST,
        payload: data,
      });
    },
    [userProfileStations, startDate, endDate]
  );

  const handleSorting = (sortItem) => {
    const sortOrder = sortByItem.item === sortItem && sortByItem.order === 'asc' ? 'desc' : 'asc';
    setSortByItem({ item: sortItem, order: sortOrder });
    const data = {
      sortBy: `${sortOrder === 'asc' ? '' : '-'}${sortItem}`,
      page: 1,
      from: startDate,
      to: endDate,
    };
    faultyBookingReport(data);
  };

  const handlePageClick = useCallback(
    (page) => {
      const data = {
        sortBy: `${sortByItem.order === 'asc' ? '' : '-'}${sortByItem.item}`,
        page: page.selected + 1,
        from: startDate,
        to: endDate,
      };
      faultyBookingReport(data);
    },
    [sortByItem, startDate, endDate]
  );

  const handlePostClick = (month) => {
    setMonth(month);

    const [year, monthIndex] = month.split('-');
    const start = moment.utc(`${year}-${monthIndex}-01`).startOf('month');
    const end = moment.utc(`${year}-${monthIndex}-01`).endOf('month');

    setStartDate(start);
    setEndDate(end);
    
    setShowProgressBar(true);
    setShowFaultyBookingReport(true);
    setShowButton(true);
  };

  useEffect(() => {
    if (startDate && endDate) {
      const data = {
        from: startDate.format(),
        to: endDate.format(),
        page: 1,
      };
      faultyBookingReport(data);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    dispatch({ type: FETCH_SAP_MONTHLY_DATA.REQUEST });
  }, [dispatch]);

  useEffect(() => {
    if (_.isEmpty(sapFaultyBookingList)) {
      setIsSapFaultyBookingNull(true);
    } else {
      setIsSapFaultyBookingNull(false);
    }
  }, [sapFaultyBookingList, setIsSapFaultyBookingNull]);

  const downloadFaultyBooking = () => {
    const data = {
      excel: true,
      isAllFaultyBookings: true,
      from: moment(startDate).tz(countryTimezone).utc(),
      to: moment(endDate).tz(countryTimezone).utc(),
    };
    dispatch({
      type: DOWNLOAD_FAULTY_BOOKING_REPORT.REQUEST,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Faulty Booking Report'}.xlsx`);
          }
        }
      },
    });
  };

  const currentMoment = moment();
  const months = moment.months();
  const filteredMonths = months.filter((month, index) => index <= currentMoment.month());
  const monthDataMap = _.keyBy(sapMonthlyData, (item) => `${String(item.month).padStart(2, '0')}`);

  return (
    <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
      <div className="space-y-4">
        {showFaultyBookingReport ? (
          <div className="data-report__table">
            <div className="report-excel-icon" style={{ display: 'block', width: '10%', float: 'right', padding: '10px' }}>
              <BsDownload title="Download" size={28} color={'#3c7cdd'} onClick={downloadFaultyBooking} />
            </div>
            <div className="table-responsive">
              <table className="record-list-table" id="table-to-xls">
                <thead>
                  <tr>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.srNo')}</span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.chargerId')}</span>
                        <span className="ico" onClick={() => handleSorting('charger_id')}>
                          <BiSort size={15} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.bookingId')}</span>
                        <span className="ico" onClick={() => handleSorting('id')}>
                          <BiSort size={15} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.transactionID')}</span>
                        <span className="ico" onClick={() => handleSorting('transaction_id')}>
                          <BiSort size={15} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.idTag')}</span>
                        <span className="ico" onClick={() => handleSorting('idTag')}>
                          <BiSort size={15} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.meterStart')}</span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.meterStop')}</span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.faultyBookingReasons')}</span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.tenant')}</span>
                      </div>
                    </th>
                    {/* <th>
                      <div>
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.action')}</span>
                      </div>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {isLoader ? (
                    <tr>
                      <td colSpan={9} className="border-0">
                        <div className="loader--block">
                          <Skeleton height={15} width={100} />
                        </div>
                      </td>
                    </tr>
                  ) : _.isEmpty(sapFaultyBookingList) ? (
                    <tr>
                      <td colSpan={10} className="border-0">
                        <div className="empty-data-block">No Faulty Booking Report Found</div>
                      </td>
                    </tr>
                  ) : (
                    _.map(sapFaultyBookingList, (item, key) => {
                      const serial_num = limit * (page - 1) + key;
                      const faultyBookingReasons = _.get(item, 'faulty_booking_reason', []);
                      const faultyBookingReasonsString = Array.isArray(faultyBookingReasons) ? faultyBookingReasons.join(', ') : faultyBookingReasons;

                      return (
                        <tr key={`faulty-booking-report-${serial_num}`}>
                          <td>{serial_num + 1}</td>
                          <td>{_.get(item, 'charger.charger_id', '-')}</td>
                          <td>{_.get(item, 'id', '-')}</td>
                          <td>{_.get(item, 'transaction_id', '-')}</td>
                          <td>{_.get(item, 'idTag', '-')}</td>
                          <td>{_.get(item, 'meterstart', '-')}</td>
                          <td>{_.get(item, 'meterstop', '-')}</td>
                          <td>{faultyBookingReasonsString}</td>
                          <td>{_.get(item, 'tenant.name', '-')}</td>
                          {/* <td>
                            <FiEdit2
                              title={'Edit'}
                              color={'green'}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setFaultyBooking(item);
                                setShowModal(true);
                              }}
                            />
                          </td> */}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="data-report__table">
            <div className="table-responsive">
              <table className="record-list-table" id="table-to-xls">
                <thead>
                  <tr>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('sap.month')}</span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting">
                        <span>{isLoader ? <Skeleton height={15} width={100} /> : t('faultyBookingReport.action')}</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoader ? (
                    <tr>
                      <td colSpan={2} className="border-0">
                        <div className="loader--block">
                          <Skeleton height={15} width={100} />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    filteredMonths.map((month, index) => {
                      const monthKey = String(index + 1).padStart(2, '0');
                      const hasData = monthDataMap[`${currentMoment.year()}-${monthKey}`];
                      const isCurrentMonth = currentMoment.month() === index;
                      setShowProgressBar(false);
                      return (
                        <tr key={monthKey}>
                          <td>{`${month}-${currentMoment.year().toString().slice(-2)}`}</td>
                          <td>
                            {isCurrentMonth ? null : hasData ? (
                              <Button onClick={() => navigateToDataCorrection(`${currentMoment.year()}-${monthKey}`)}>Data Sent</Button>
                            ) : (
                              <Button onClick={() => handlePostClick(`${currentMoment.year()}-${monthKey}`)}>Post</Button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {showFaultyBookingReport && !(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(sapFaultyBookingList) && (
          <ReactPagination
            currentPage={page}
            limit={limit}
            total={totalData}
            handlePageClick={handlePageClick}
            totalPages={totalPages}
            marginPagesDisplayed={1}
          />
        )}
        <UpdateFaultyBooking show={showModal} onHide={() => setShowModal(false)} faultyBooking={faultyBooking} />
      </div>
    </SkeletonTheme>
  );
};

DataCorrection.propTypes = {
  setMonth: PropTypes.func.isRequired,
  setShowButton: PropTypes.func.isRequired,
  setIsSapFaultyBookingNull: PropTypes.func.isRequired,
  setShowProgressBar: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

export default DataCorrection;