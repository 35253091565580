import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FieldArray } from 'formik';
import { Row, Col } from 'react-bootstrap';
import Button from 'components/inputs/Button';
import TextInput from 'components/inputs/Input';
import * as Yup from 'yup';
import CheckBox from 'components/inputs/CheckBox';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { BiTrash, BiPlus } from 'react-icons/bi';

const TimingInfo = ({ formData, setFormData, setIsStepValid, isButtonClicked }) => {
  const { t } = useTranslation();
  const supportApp = useSelector((state) => _.get(state.profile.userProfile, 'tenant.support_cz_app_booking', ''));

  const validationSchema = Yup.object().shape({
    working_hours: Yup.array().of(
      Yup.object().shape({
        from: Yup.string().required('Time is required'),
        to: Yup.string().required('Time is required'),
      })
    ),
  });

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnChange={true}
      enableReinitialize={true}
      onSubmit={(values) => {
        setFormData(values);
      }}
    >
      {({ values, errors, setFieldValue, validateForm }) => {
        // Validate and update parent
        React.useEffect(() => {
          const checkValidity = async () => {
            const validationResult = await validateForm();
            const isFormValid = Object.keys(validationResult).length === 0;
            setIsStepValid(isFormValid);
            setFormData(values);
          };
          checkValidity();
        }, [values, validateForm]);

        return (
          <Form className="space-y-4">
            <h5 className="text-xl font-semibold mb-4">
              {t('addStationForm.operationalTimings')}
            </h5>

            <FieldArray
              name="working_hours"
              render={(arrayHelpers) => (
                <div className="space-y-4">
                  {values.working_hours && values.working_hours.map((hours, index) => (
                    <Row key={index} className="p-2 items-center">
                      <Col lg={6} md={7}>
                        <Row>
                          <Col>
                            <TextInput
                              isRequired
                              type="time"
                              label={t('addStationForm.hourFrom')}
                              name={`working_hours.${index}.from`}
                              value={hours.from || ''}
                              onChange={(e) => {
                                setFieldValue(`working_hours.${index}.from`, e.target.value);
                                setFormData({
                                  ...values,
                                  working_hours: values.working_hours.map((item, i) => 
                                    i === index ? { ...item, from: e.target.value } : item
                                  )
                                });
                              }}
                              error={_.get(isButtonClicked && errors, `working_hours.${index}.from`)}
                            />
                          </Col>
                          <Col>
                            <TextInput
                              isRequired
                              type="time"
                              label={t('addStationForm.hourTo')}
                              name={`working_hours.${index}.to`}
                              value={hours.to || ''}
                              onChange={(e) => {
                                setFieldValue(`working_hours.${index}.to`, e.target.value);
                                setFormData({
                                  ...values,
                                  working_hours: values.working_hours.map((item, i) => 
                                    i === index ? { ...item, to: e.target.value } : item
                                  )
                                });
                              }}
                              error={_.get(isButtonClicked && errors, `working_hours.${index}.to`)}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <div className="flex items-end justify-end h-full">
                        {index > 0 && (
                          <Button
                            onClick={() => {
                              arrayHelpers.remove(index);
                              setFormData({
                                ...values,
                                working_hours: values.working_hours.filter((_, i) => i !== index)
                              });
                            }}
                            className="field--btn"
                            aria-label="Delete time slot"
                          >
                            <BiTrash size={26} />
                          </Button>
                        )}
                      </div>
                    </Row>
                  ))}

                  <button
                    type="button"
                    onClick={() => {
                      const newTimeSlot = {
                        from: '',
                        to: '',
                        availability_type: 'Available'
                      };
                      arrayHelpers.push(newTimeSlot);
                      setFormData({
                        ...values,
                        working_hours: [...values.working_hours, newTimeSlot]
                      });
                    }}
                    className="station-btn-config"
                  >
                    <BiPlus size={22} className="plus--icon" /> {t('Add time slot')}
                  </button>
                </div>
              )}
            />

                 <div className="station-checkbox-config">
          <Col lg={'auto'}>
                <div className="show-on-mobile-field-main--block">
                  <div className="show-on-mobile-checkbox-input__block">
                    <label htmlFor="show_on_mobile_app" className="show-on-mobile--label mx-1">
                      {t('addStationForm.showOnMobileApp')}
                    </label>
                    <CheckBox
                      name="show_on_mobile_app"
                      checked={values.show_on_mobile_app || false}
                      onChange={(e) => {
                        // setFieldValue('show_on_mobile_app', e.target.checked);
                        setFormData(prev => ({...prev, show_on_mobile_app: e.target.checked}));
                      }}
                    />
                  </div>
                </div>
              </Col>

              {supportApp && (
                <Col lg={'auto'}>
                  <div className="show-on-mobile-field-main--block">
                    <div className="show-on-mobile-checkbox-input__block">
                      <label htmlFor="show_on_cz_app" className="show-on-mobile--label mx-1">
                        {t('addStationForm.showOnChargezoneApp')}
                      </label>
                      <CheckBox
                        name="show_on_cz_app"
                        checked={values.show_on_cz_app || false}
                        onChange={(e) => {
                          // setFieldValue('show_on_cz_app', e.target.checked);
                          setFormData(prev => ({...prev, show_on_cz_app: e.target.checked}));
                        }}
                      />
                    </div>
                  </div>
                </Col>
              )}

              <Col lg={'auto'}>
                <div className="show-on-mobile-field-main--block">
                  <div className="show-on-mobile-checkbox-input__block">
                    <label htmlFor="show_to_ocpi_partners" className="show-on-mobile--label mx-1">
                      {t('addStationForm.showToOcpiPartner')}
                    </label>
                    <CheckBox
                      name="show_to_ocpi_partners"
                      checked={values.show_to_ocpi_partners || false}
                      onChange={(e) => {
                        // setFieldValue('show_to_ocpi_partners', e.target.checked);
                        setFormData(prev => ({...prev, show_to_ocpi_partners: e.target.checked}));
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col lg={'auto'}>
                <div className="show-on-mobile-field-main--block">
                  <div className="show-on-mobile-checkbox-input__block">
                    <label htmlFor="skip_advance_booking" className="show-on-mobile--label mx-1">
                      {t('addStationForm.skipAdvanceBooking')}
                    </label>
                    <CheckBox
                      name="skip_advance_booking"
                      checked={values.skip_booking || false}
                      onChange={(e) => {
                        // setFieldValue('show_to_ocpi_partners', e.target.checked);
                        setFormData(prev => ({...prev, skip_booking: e.target.checked}));
                      }}
                    />
                  </div>
                </div>
              </Col>
              {supportApp && (
                <Col lg={'auto'}>
                  <div className="show-on-mobile-field-main--block">
                    <div className="show-on-mobile-checkbox-input__block">
                      <label htmlFor="is_chargecoin_disabled" className="show-on-mobile--label mx-1">
                        {t('addStationForm.isDisableChargecoin')}
                      </label>
                      <CheckBox
                        name="is_chargecoin_disabled"
                        checked={values.is_chargecoin_disabled || false}
                        onChange={(e) => {
                          // setFieldValue('is_chargecoin_disabled', e.target.checked);
                          setFormData(prev => ({...prev, is_chargecoin_disabled: e.target.checked}));
                        }}
                      />
                    </div>
                  </div>
                </Col>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

TimingInfo.propTypes = {
  formData: PropTypes.shape({
    working_hours: PropTypes.arrayOf(
      PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
        availability_type: PropTypes.string
      })
    ),
    show_on_mobile_app: PropTypes.bool
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  setIsStepValid: PropTypes.func.isRequired,
  isButtonClicked: PropTypes.bool.isRequired
};

export default TimingInfo;