import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { GiSwipeCard } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import fileDownload from 'js-file-download';
import _ from 'lodash';
import { UPLOAD_BULK_BOOKING_TIME, UPLOAD_BULK_METER_VALUES_B2B, UPLOAD_BULK_METER_VALUES_B2C } from 'actions/updateBulkNegativeValue';
import { updateMeterValuesAPI } from 'api/negativeValue';
import TextInput from 'components/inputs/Input';
import RadioButton from 'components/inputs/RadioButton';
import CheckBox from 'components/inputs/CheckBox';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';

const UpdateFaultyBooking = ({ show, onHide, faultyBooking }) => {
  const { t } = useTranslation();
  const [bookingId, setBookingId] = useState(faultyBooking?.id || '');
  const [meterstart, setMeterstart] = useState(faultyBooking?.meterstart || '');
  const [meterstop, setMeterstop] = useState(faultyBooking?.meterstop || '');
  const [dateStart, setDateStart] = useState(faultyBooking?.booking_start ? moment(faultyBooking.booking_start).format('YYYY-MM-DD') : '');
  const [timeStart, setTimeStart] = useState(faultyBooking?.booking_start ? moment(faultyBooking.booking_start).format('HH:mm') : '');
  const [dateStop, setDateStop] = useState(faultyBooking?.booking_stop ? moment(faultyBooking.booking_stop).format('YYYY-MM-DD') : '');
  const [timeStop, setTimeStop] = useState(faultyBooking?.booking_stop ? moment(faultyBooking.booking_stop).format('HH:mm') : '');

  useEffect(() => {
    setBookingId(faultyBooking?.id || '');
    setMeterstart(faultyBooking?.meterstart || '');
    setMeterstop(faultyBooking?.meterstop || '');
    setDateStart(faultyBooking?.booking_start ? moment(faultyBooking.booking_start).format('YYYY-MM-DD') : '');
    setTimeStart(faultyBooking?.booking_start ? moment(faultyBooking.booking_start).format('HH:mm') : '');
    setDateStop(faultyBooking?.booking_stop ? moment(faultyBooking.booking_stop).format('YYYY-MM-DD') : '');
    setTimeStop(faultyBooking?.booking_stop ? moment(faultyBooking.booking_stop).format('HH:mm') : '');
  }, [faultyBooking]);

  const [isInputMode, setIsInputMode] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const dispatch = useDispatch();

  const updateBulkBookings = useCallback((data) => {
    dispatch({
      type: UPLOAD_BULK_BOOKING_TIME.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === 200) {
          toast.success('Data Updated Successfully');
          fileDownload(res.data, `${'Booking Dates'}.xlsx`);
        } else {
          toast.error('An error occurred while updating list.');
        }
      },
    });
  }, []);
  const processExcel = (data) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
    if (isInputMode) {
      if (!isChecked) {
        updateBulkMeterValuesB2C(excelRows);
      } else {
        updateBulkMeterValuesB2B(excelRows);
      }
    } else {
      _.map(excelRows, (data) => {
        const startDateTime = (data['startDate (MM/DD/YYYY)'] - 25569) * 86400 * 1000 + data['startTime (HH:mm:ss)'] * 86400 * 1000;
        const formattedStartDate = moment(startDateTime).subtract(330, 'minutes');
        data.booking_start = formattedStartDate;
        delete data['startDate (MM/DD/YYYY)'];
        delete data['startTime (HH:mm:ss)'];

        const stopDateTime = (data['stopDate (MM/DD/YYYY)'] - 25569) * 86400 * 1000 + data['stopTime (HH:mm:ss)'] * 86400 * 1000;
        const formattedStopDate = moment(stopDateTime).subtract(330, 'minutes');
        data.booking_stop = formattedStopDate;
        delete data['stopDate (MM/DD/YYYY)'];
        delete data['stopTime (HH:mm:ss)'];
      });
      updateBulkBookings(excelRows);
    }
  };
  const updateBulkMeterValuesB2C = useCallback((data) => {
    dispatch({
      type: UPLOAD_BULK_METER_VALUES_B2C.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === 200) {
          toast.success('Data Updated Successfully');
          fileDownload(res.data, `${'Meter Values B2C'}.xlsx`);
        } else {
          toast.error('An error occurred while updating list.');
        }
      },
    });
  }, []);
  const handleChange = () => {
    setIsChecked(!isChecked);
  };
  const updateBulkMeterValuesB2B = useCallback((data) => {
    dispatch({
      type: UPLOAD_BULK_METER_VALUES_B2B.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === 200) {
          toast.success('Data Updated Successfully');
          fileDownload(res.data, `${'Meter Values B2B'}.xlsx`);
        } else {
          toast.error('An error occurred while updating list.');
        }
      },
    });
  }, []);
  const handleRadioChange = (event) => {
    const value = event.target.value === 'input';
    setIsInputMode(value);
    if (!value) {
      setMeterstart('');
      setMeterstop('');
    }
  };
  const upload = () => {
    const fileUpload = document.getElementById('fileUpload');
    const regex = /^([a-zA-Z0-9\s_\\.\-:()])+(.xls|.xlsx)$/;
    if (regex.test(fileUpload.value.toLowerCase())) {
      // let fileName = fileUpload.files[0].name;
      if (typeof FileReader !== 'undefined') {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = () => {
            processExcel(reader.result);
          };
          reader.readAsBinaryString(fileUpload.files[0]);
        }
      } else {
        toast.error('This browser does not support HTML5.');
      }
    } else {
      toast.error('Please upload a valid Excel file.');
    }
  };
  const downloadSample = (file, fileName) => {
    // download static Excel file
    const ws = XLSX.utils.json_to_sheet(file);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    // Adjust column widths based on content length
    const wscols = [];
    XLSX.utils.sheet_to_json(ws, { header: 1 }).forEach((row) => {
      row.forEach((col, i) => {
        if (!wscols[i]) {
          wscols[i] = { wch: 0 };
        }
        if (col && col.toString().length + 2 > wscols[i].wch) {
          wscols[i].wch = col.toString().length + 20;
        }
      });
    });
    ws['!cols'] = wscols;

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const sampleMeterValuesList = isInputMode
    ? !isChecked
      ? [
          {
            bookingId: '',
            meterStart: '',
            meterStop: '',
          },
        ]
      : [
          {
            chargerBookingId: '',
            meterstart: '',
            meterstop: '',
          },
        ]
    : [
        {
          chargerBookingId: '',
          'startDate (MM/DD/YYYY)': '',
          'startTime (HH:mm:ss)': '',
          'stopDate (MM/DD/YYYY)': '',
          'stopTime (HH:mm:ss)': '',
        },
      ];
  const handleClearFieldsMeterValue = () => {
    setBookingId('');
    setDateStart('');
    setTimeStart('');
    setDateStop('');
    setTimeStop('');
    setMeterstart('');
    setMeterstop('');
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (isInputMode && meterstart === '') {
      toast.error('Please fill all fields');
      return;
    }

    if (isInputMode && meterstop === '') {
      toast.error('Please fill all fields');
      return;
    }

    if (parseFloat(meterstart) > parseFloat(meterstop)) {
      toast.error('MeterStart must be less then MeterStop');
      return;
    }

    if (!isInputMode && (!dateStart || !timeStart || !dateStop || !timeStop)) {
      toast.error('Please fill in the date and time');
      return;
    }

    if (!isInputMode) {
      try {
        const startDate = new Date(`${dateStart}T${timeStart}:00.000Z`);
        const stopDate = new Date(`${dateStop}T${timeStop}:00.000Z`);

        if (startDate.getTime() > stopDate.getTime()) {
          toast.error('End Time must be greater than Start Time');
          return;
        }
      } catch (error) {
        // Handle the error appropriately, such as displaying an error message or logging the error.
        toast.error('Ensure date time combination is correct');
        return;
      }
    }

    try {
      let formattedStartDate = '';
      let formattedStopDate = '';

      if (isInputMode) {
        formattedStartDate = new Date().toISOString();
        formattedStopDate = new Date().toISOString();
      } else {
        const iststartDate = new Date(`${dateStart}T${timeStart}:00.000Z`);
        formattedStartDate = new Date(iststartDate.getTime() - iststartDate.getTimezoneOffset() * -60000).toISOString();

        const iststopDate = new Date(`${dateStop}T${timeStop}:00.000Z`);
        formattedStopDate = new Date(iststopDate.getTime() - iststopDate.getTimezoneOffset() * -60000).toISOString();
      }

      await updateMeterValuesAPI(
        bookingId,
        isInputMode ? meterstart : null,
        isInputMode ? meterstop : null,
        formattedStartDate,
        formattedStopDate,
        isChecked
      );
      toast.success('Data Updated ');
    } catch (error) {
      toast.error('Failed to update meter values:', error);
    }
    handleClearFieldsMeterValue();
    onHide();
  };
  return (
    <React.Fragment>
      <Modal show={show} onHide={onHide} size="xl">
        <Modal.Body>
          <div className="updateFaultyBooking">
            <h4>{t('addNegetivemetervalue.updateFaultyBooking')}</h4>
            <div className="booking-close-btn">
              <MdClose size={30} color="#be210b" onClick={onHide} />
            </div>
            <form onSubmit={handleFormSubmit}>
              <Row className="align-items-center">
                <Col lg={6} md={6}>
                  <TextInput
                    label="Booking ID"
                    placeholder={t('placeHolder.bookingID')}
                    name="Booking"
                    type="text"
                    value={bookingId}
                    onChange={(e) => setBookingId(e.target.value)}
                  />
                </Col>
                <Col lg={6} md={6}>
                  <div className="radioModule">
                    <RadioButton containerClassName="radio-field" checked={isInputMode} onChange={handleRadioChange} value="input">
                      {t('addNegetivemetervalue.meterValue')}
                    </RadioButton>
                    <RadioButton containerClassName="radio-field" value="date" checked={!isInputMode} onChange={handleRadioChange}>
                      {t('addNegetivemetervalue.chooseDate')}
                    </RadioButton>
                  </div>
                  {isInputMode && (
                    <Row>
                      <Col lg={6} md={6}>
                        <div className="radioModule">
                          <CheckBox containerClassName="radio-field" checked={!isChecked} onChange={handleChange}>
                            {t('addNegetivemetervalue.b2c')}
                          </CheckBox>
                          <CheckBox containerClassName="radio-field" checked={isChecked} onChange={handleChange}>
                            {t('addNegetivemetervalue.b2b')}
                          </CheckBox>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
                {isInputMode ? (
                  <Col lg={6} md={6}>
                    <Row>
                      <Col lg={6} md={6}>
                        <TextInput
                          label="Meter Start"
                          placeholder={t('placeHolder.meterStart')}
                          name="meter_start"
                          type="text"
                          value={meterstart}
                          onChange={(e) => setMeterstart(e.target.value)}
                        />
                      </Col>
                      <Col lg={6} md={6}>
                        <TextInput
                          label="Meter Stop"
                          placeholder={t('placeHolder.meterStop')}
                          name="meter_stop"
                          type="text"
                          value={meterstop}
                          onChange={(e) => setMeterstop(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <>
                    <Col lg={6} md={6}>
                      <Row>
                        <p>{t('placeHolder.startTime')}</p>
                        <Col lg={6} md={6}>
                          <TextInput type="date" name="meterstartdate" value={dateStart} onChange={(e) => setDateStart(e.target.value)} />
                        </Col>
                        <Col lg={6} md={6}>
                          <TextInput type="time" name="meterstarttime" value={timeStart} onChange={(e) => setTimeStart(e.target.value)} />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={6}>
                      <Row>
                        <p>{t('placeHolder.stopTime')}</p>
                        <Col lg={6} md={6}>
                          <TextInput type="date" name="meterenddate" value={dateStop} onChange={(e) => setDateStop(e.target.value)} />
                        </Col>
                        <Col lg={6} md={6}>
                          <TextInput type="time" name="meterendtime" value={timeStop} onChange={(e) => setTimeStop(e.target.value)} />
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </Row>

              <Col lg={6} md={12}>
                <div className="import-admin-block">
                  <TextInput
                    label={isInputMode ? t('addNegetivemetervalue.importMeterValues') : t('addNegetivemetervalue.importBookingTime')}
                    type="file"
                    onChange={upload}
                    name="fileUpload"
                    id="fileUpload"
                  />
                  <span className="download-sample-block">
                    <div
                      className="download-sample--file"
                      onClick={() => downloadSample(sampleMeterValuesList, isInputMode ? 'MeterValues' : 'BookingTime')}
                    >
                      <GiSwipeCard color="#58d37d" size={20} />(
                      {isInputMode ? t('addNegetivemetervalue.sampleMeterValueList') : t('addNegetivemetervalue.sampleBookingTimeList')})
                    </div>
                  </span>
                </div>
              </Col>
              <div className="button-group">
                <button type="submit">{t('button.update')}</button>
                <button type="button" id="clear" onClick={handleClearFieldsMeterValue}>
                  {t('button.clearField')}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

UpdateFaultyBooking.propTypes = {
  show: PropTypes.string,
  onHide: PropTypes.string,
  faultyBooking: PropTypes.object,
};

export default UpdateFaultyBooking;
