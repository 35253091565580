import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import DateTimePicker from 'components/inputs/DateTimePicker';
// import TextInput from 'components/inputs/Input';
import { GiSwipeCard } from 'react-icons/gi';
import { GET_STATION_BY_CITY } from 'actions/chargingStation';
import { UPLOAD_NO_LOAD_REPORT, FETCH_NO_LOAD_REPORT, DOWNLOAD_NO_LOAD_REPORT } from 'actions/noLoadReport';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';
import { BiSort } from 'react-icons/bi';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import ReactPagination from 'components/general/ReactPagination';
import fileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import Button from 'components/inputs/Button';
import { Modal } from 'react-bootstrap';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { SiGooglesheets } from 'react-icons/si';
import { FiUpload } from 'react-icons/fi';
import { setShowErrorToast } from 'utils/api';

const NoLoadReport = () => {
  const errorMessage = setShowErrorToast(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const { navigateTo } = useContext(NavContext);
  const [startDate, setStartDate] = useState(moment(new Date()).startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [filePopupVisible, setFilePopupVisible] = useState(false); // State to manage file upload popup visibility
  const [selectedFileName, setSelectedFileName] = useState('');

  const noLoadReportList = useSelector((state) => state.noLoadReport.noLoadReports);
  const isLoader = useSelector((state) => state.noLoadReport.isLoading);
  const page = useSelector((state) => state.noLoadReport.page);
  const totalData = useSelector((state) => state.noLoadReport.total);
  const limit = useSelector((state) => state.noLoadReport.limit);
  const totalPages = useSelector((state) => state.noLoadReport.totalPages);
  const allStationByCity = useSelector((state) => state.stationByCity.stationByCities);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const currentUserName = _.get(profileData, 'name', '');
  const stationList =
    userProfileStations && userProfileStations.length > 0
      ? allStationByCity.filter((x) => userProfileStations.indexOf(x.id) !== -1)
      : allStationByCity;

  const sampleNoLoadReport = {
    from: '',
    to: '',
    stations: [
      {
        station_id: '',
        station_name: '',
        ac_meter_reading: '',
      },
    ],
  };

  const noLoadReport = useCallback((value) => {
    const data = {
      ...value,
    };
    dispatch({
      type: FETCH_NO_LOAD_REPORT.REQUEST,
      payload: data,
    });
  }, []);

  const uploadNoLoadReport = useCallback((data) => {
    dispatch({
      type: UPLOAD_NO_LOAD_REPORT.REQUEST,
      payload: data,
      cb: (res) => {
        console.log(res, 'upload report res');
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          toast.success('No Loaded Data added successfully');
          setTimeout(() => {
            window.location.reload();
            navigateTo('/noLoadReport');
          }, 1000);
        }
        if (_.get(res, 'status') === 400) {
          {
            errorMessage;
          }
          const messagesArray = _.get(res, 'data.message').split(', ');
          const singleMessage = messagesArray[0];
          toast.error(singleMessage);
          setTimeout(() => {
            window.location.reload();
            navigateTo('/noLoadReport');
          }, 1000);
        }
      },
    });
  }, []);

  const downloadNoLoad = useCallback((id) => {
    const data = {
      excel: true,
      id,
    };
    dispatch({
      type: DOWNLOAD_NO_LOAD_REPORT.REQUEST,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('No Loaded Data Downloaded successfully');
            fileDownload(res.data, `${'No Load Report'}.xlsx`);
          }
        }
      },
    });
  }, []);

  const downloadFile = (data) => {
    downloadNoLoad(data);
  };

  const getSortByItem = useCallback(
    (name, order, page) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).utc().toDate(),
          to: moment(endDate).utc().toDate(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        endDate && noLoadReport(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        noLoadReport(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        noLoadReport(data);
      }
    },
    [startDate, endDate]
  );
  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).utc().toDate(),
          to: moment(endDate).utc().toDate(),
          page: page.selected + 1,
        };
        noLoadReport(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        noLoadReport(data);
      }
    },
    [sortByItem, startDate, endDate]
  );

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      // access_type: 'Public',
    };
    dispatch({ type: GET_STATION_BY_CITY.REQUEST, payload: stationData });
  }, []);

  useEffect(() => {
    getAllStation();
    noLoadReport();
  }, []);

  const handleFileUpload = () => {
    setFilePopupVisible(true);
  };

  const closeFileUpload = () => {
    setFilePopupVisible(false);
    setSelectedFileName('');
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFileName(file.name);
    if (file) {
      processExcel(file);
    }
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files[0];
    setSelectedFileName(droppedFiles.name);
    if (droppedFiles) {
      processExcel(droppedFiles);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const processExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const firstSheet = workbook.SheetNames[0];
      const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
      const modifiedData = {
        from: excelRows[0].from,
        to: excelRows[0].to,
        stations: excelRows.map((item) => ({
          station_id: item.station_id,
          station_name: item.station_name,
          ac_meter_reading: item.ac_meter_reading,
        })),
      };
      uploadNoLoadReport(modifiedData);
    };
    reader.readAsBinaryString(file);
  };

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const downloadNoLoadSampleReport = (file, fileName, stationList) => {
    // download static Excel file

    const modifiedArr = file.stations.map((item) => {
      return {
        station_id: item.station_id,
        station_name: item.station_name,
        ac_meter_reading: item.ac_meter_reading,
        from: '',
        to: '',
      };
    });

    const fileData = modifiedArr;
    const ws = XLSX.utils.json_to_sheet(fileData);
    const wb = { Sheets: { NoLoadData: ws }, SheetNames: ['NoLoadData'] };

    if (stationList && stationList.length > 0) {
      stationList.forEach((station, index) => {
        const rowIndex = index + 2;
        const cellAddress = `B${rowIndex}`;
        const cellInvite = `A${rowIndex}`;
        const cellName = `D${rowIndex}`;
        const cellTime = `E${rowIndex}`;
        XLSX.utils.sheet_add_json(ws, [{ station_name: station.name }], { header: ['station_name'], skipHeader: true, origin: cellAddress });
        XLSX.utils.sheet_add_json(ws, [{ station_id: station.id }], { header: ['station_id'], skipHeader: true, origin: cellInvite });
        XLSX.utils.sheet_add_json(ws, [{ from: startDate.format('DD-MM-YYYY') }], { header: ['from'], skipHeader: true, origin: cellName });
        XLSX.utils.sheet_add_json(ws, [{ to: endDate.format('DD-MM-YYYY') }], { header: ['to'], skipHeader: true, origin: cellTime });
      });
    }

    // Adjust column widths based on content length
    const wscols = [];
    XLSX.utils.sheet_to_json(ws, { header: 1 }).forEach((row) => {
      row.forEach((col, i) => {
        if (!wscols[i]) {
          wscols[i] = { wch: 0 };
        }
        if (col && col.toString().length + 2 > wscols[i].wch) {
          wscols[i].wch = col.toString().length + 2;
        }
      });
    });
    ws['!cols'] = wscols;

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.noLoad')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col lg={'auto'}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment(new Date()).startOf('day')}
                            initialValueOfEndDate={moment(new Date()).endOf('day')}
                            closeOnSelect={true}
                          />
                        </div>
                      </Col>
                      <Col lg={'auto'} md={'auto'}>
                        <div className="No-Load-block">
                          <Button onClick={handleFileUpload}>
                            <FiUpload size={20} /> {t('button.uploadFile')}
                          </Button>
                          <span className="download-sample--block">
                            <div
                              className="download-sample-file"
                              onClick={() => downloadNoLoadSampleReport(sampleNoLoadReport, 'NoLoadReprot', stationList)}
                            >
                              <GiSwipeCard color="#58d37d" size={20} />
                              {t('dataReport.downloadSampleNoLoadReport')}
                            </div>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {filePopupVisible && (
                    <Modal show={filePopupVisible} centered onHide={closeFileUpload}>
                      <div className="file-upload-popup">
                        <div className="file-drop-area" onClick={handleClick} onDrop={handleFileDrop} onDragOver={handleDragOver}>
                          {selectedFileName ? (
                            <p className="small-text">
                              <SiGooglesheets style={{ fontSize: '24px', marginBottom: '5px', color: '#5dad5d' }} />
                              <br />
                              {selectedFileName}
                            </p>
                          ) : (
                            <>
                              <p className="small-text">
                                <FaCloudUploadAlt style={{ fontSize: '24px', marginBottom: '5px' }} />
                                <br />
                                {t('dataReport.dragDrop')}
                              </p>
                              <input type="file" ref={fileInputRef} onChange={handleFileSelect} style={{ display: 'none' }} />
                            </>
                          )}
                        </div>
                        <Button onClick={closeFileUpload}> {t('button.cancel')}</Button>
                      </div>
                    </Modal>
                  )}

                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.fromDate')}</span>
                                <span className="ico" onClick={() => handleSorting('from', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.toDate')}</span>
                                <span className="ico" onClick={() => handleSorting('to', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.action')}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={12} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(noLoadReportList) ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">{t('dataReport.noLoadedData')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(
                              _.filter(noLoadReportList, (item) => item !== null),
                              (item, key) => {
                                const serial_num = limit * (page - 1) + key;
                                return (
                                  <>
                                    <tr key={`no-load-report-${serial_num}`}>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : moment(item.from).format('DD-MM-YYYY')}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : moment(item.to).format('DD-MM-YYYY')}</td>
                                      <td>
                                        {isLoader ? (
                                          <Skeleton circle={true} height={30} width={100} />
                                        ) : (
                                          <div>
                                            <Button onClick={() => downloadFile(_.get(item, 'id'))}>{t('button.download')}</Button>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(noLoadReportList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
            {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
          </div>
        </SkeletonTheme>
      </LayoutContainer>
    </>
  );
};

export default NoLoadReport;
