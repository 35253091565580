import { CHARGER_STATUS, FETCH_CHARGER_STATUS_WIDGETS } from 'actions/chargerStatus';

const initialState = {
  isLoading: false,
  chargerStatus: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const chargerStatusReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHARGER_STATUS.REQUEST:
    case FETCH_CHARGER_STATUS_WIDGETS.REQUEST: {
      return { ...state, isLoading: true };
    }
    case CHARGER_STATUS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        chargerStatus: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case FETCH_CHARGER_STATUS_WIDGETS.SUCCESS: {
      return {
        ...state,
        totalChargers: payload.data?.totalChargers,
        available: payload.data?.availableCount,
        unavailable: payload.data?.unavailableCount,
        inuse: payload.data?.inUseCount,
        PowerLossCount: payload.data?.powerLossCount,
        availableCount: payload.data?.availableCount,
        chargingCount: payload.data?.chargingCount,
        draftCount: payload.data?.draftCount,
        faultedCount: payload.data?.faultedCount,
        finishingCount: payload.data?.finishingCount,
        preparingCount: payload.data?.preparingCount,
        totalChargersPlugs: payload.data?.totalChargersPlugs,
        unavailableCount: payload.data?.unavailableCount,
        emergencyStopCount: payload.data?.emergencyStopCount,
        underVoltage: payload.data?.underVoltage,
        overVoltage: payload.data?.overVoltage,
        underMaintenance: payload.data?.underMaintenance,
        comingSoon: payload.data?.comingSoon,
      };
    }

    case CHARGER_STATUS.FAIL:
    case FETCH_CHARGER_STATUS_WIDGETS.FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default chargerStatusReducer;
