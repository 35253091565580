export const initialFormData = {
    name: '',
    access_type: '',
    description: '',
    status: '',
    does_support_home_charging: false,
    home_charging_code: '',
    address: '',
    location: {
      lat: '',
      lng: '',
    },
    country: '',
    state: '',
    city: '',
    area: '',
    pincode: '',
    contact_name: '',
    contact_phone: '',
    contact_email: '',
    contact_address: '',
    amenity: [],
    settings: {
      energy_limits: '',
      DISCOM_provider: '',
      notification: ['Email', 'SMS'],
    },
    timing_type: 'daily',
    working_hours: [
      {
        day: '',
        from: '',
        to: '',
        availability_type: 'Available',
      },
    ],
    photos: [],
    partner_logo: '',
    show_on_mobile_app: true,
    show_to_ocpi_partners: true,
    is_active: true,
    skip_booking: false
  };