import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_SAP_MONTHLY_DATA,
  SEND_SAP_INVOICE_B2C_RECORDS_CGST_CGST,
  SEND_SAP_INVOICE_B2C_RECORDS_IGST,
  DOWNLOAD_INVOICE_B2C,
  SAP_FAULTY_BOOKING_REPORT,
} from 'actions/sap';

function* fetchSapMonthlyData(action) {
  try {
    const response = yield call(API.getSapMonthlyData, action.payload);
    yield put({ type: FETCH_SAP_MONTHLY_DATA.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_SAP_MONTHLY_DATA.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadInvoiceB2C(action) {
  try {
    const response = yield call(API.downloadInvoiceB2C, action.payload);
    yield put({ type: DOWNLOAD_INVOICE_B2C.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_INVOICE_B2C.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* sendSAPInvoiceB2CRecordsIGST(action) {
  try {
    const response = yield call(API.sendSAPInvoiceB2CRecordsIGST, action.payload);
    yield put({ type: SEND_SAP_INVOICE_B2C_RECORDS_IGST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: SEND_SAP_INVOICE_B2C_RECORDS_IGST.FAIL, payload: { error: e } });
  }
}

function* sendSAPInvoiceB2CRecordsCGSTSGST(action) {
  try {
    const response = yield call(API.sendSAPInvoiceB2CRecordsCGSTSGST, action.payload);
    yield put({ type: SEND_SAP_INVOICE_B2C_RECORDS_CGST_CGST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: SEND_SAP_INVOICE_B2C_RECORDS_CGST_CGST.FAIL, payload: { error: e } });
  }
}

function* sapFaultyBookingReport(action) {
  try {
    const response = yield call(API.sapFaultyBookingReport, action.payload);
    yield put({ type: SAP_FAULTY_BOOKING_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: SAP_FAULTY_BOOKING_REPORT.FAIL, payload: { error: e } });
  }
}

function* sap() {
  yield takeLatest(FETCH_SAP_MONTHLY_DATA.REQUEST, fetchSapMonthlyData);
  yield takeLatest(SEND_SAP_INVOICE_B2C_RECORDS_IGST.REQUEST, sendSAPInvoiceB2CRecordsIGST);
  yield takeLatest(SEND_SAP_INVOICE_B2C_RECORDS_CGST_CGST.REQUEST, sendSAPInvoiceB2CRecordsCGSTSGST);
  yield takeLatest(DOWNLOAD_INVOICE_B2C.REQUEST, downloadInvoiceB2C);
  yield takeLatest(SAP_FAULTY_BOOKING_REPORT.REQUEST, sapFaultyBookingReport);
}
export default sap;
