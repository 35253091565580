import React from 'react';
import PropTypes from 'prop-types';

const StepIndicator = ({ steps, currentStep }) => (
  <div className="mb-4">
  <div className="d-flex justify-content-between mb-3">
    {steps.map((step) => (
      <div
        key={step.id}
        className={`flex-grow-1 text-center ${
          step.id === currentStep 
            ? 'text-white' 
            : step.id < currentStep 
              ? 'text-danger' 
              : 'text-secondary'
        }`}
      >
        <div className="d-flex flex-column align-items-center">
          <div
            className={`p-2 rounded-circle mb-2 ${
              step.id === currentStep
                ? 'bg-white bg-opacity-25'
                : step.id < currentStep
                  ? 'bg-danger bg-opacity-25'
                  : 'bg-secondary bg-opacity-25'
            }`}
            style={{ width: '40px', height: '40px' }}
          >
            {step.icon}
          </div>
          <span className={`fw-${step.id === currentStep ? 'bold' : 'normal'}`}>
            {step.title}
          </span>
        </div>
      </div>
    ))}
  </div>
  <div className="progress" style={{ height: '5px' }}>
    <div
      className="progress-bar bg-danger"
      role="progressbar"
      style={{ width: `${((currentStep - 1) / (steps.length - 1)) * 100}%` }}
      aria-valuenow={((currentStep - 1) / (steps.length - 1)) * 100}
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
</div>
);

// Define prop types for validation
StepIndicator.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
    })
  ).isRequired,
  currentStep: PropTypes.number.isRequired,
};


export default StepIndicator;