import React, { useState } from 'react';
import DataValidation from 'view/sap/steps/DataValidation';
import SapPosting from 'view/sap/steps/SapPosting';
import DataCollection from 'view/sap/steps/DataCorrection';
import StepIndicator from './StepIndicator';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';

const PasscodeSchema = Yup.object().shape({
  passcode: Yup.string().required('Passcode is required').min(4, 'Passcode must be at least 4 characters long'),
});
const StepForm = () => {
  const { t } = useTranslation();
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');
  const [currentStep, setCurrentStep] = useState(1);
  const [showPasscodeModal, setShowPasscodeModal] = useState(false);
  const [month, setMonth] = useState('');
  const [showButton, setShowButton] = useState(false);
  const [isSapFaultyBookingNull, setIsSapFaultyBookingNull] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(true);
  
  const handleNext = () => {
    if (currentStep === 2) {
      setShowPasscodeModal(true);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };
  
  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  
  const handlePasscodeSubmit = (passcode, { setFieldError }) => {
    if (passcode === '1111') {
      setShowPasscodeModal(false);
      setCurrentStep(currentStep + 1);
    } else {
      setFieldError('passcode', 'Incorrect passcode!');
    }
  };
  const steps = [
    {
      id: 1,
      title: 'Data Correction',
      component: DataCollection,
    },
    {
      id: 2,
      title: 'Data Validation',
      component: DataValidation,
    },
    {
      id: 3,
      title: 'SAP Posting',
      component: SapPosting,
    },
  ];

  const CurrentStepComponent = steps.find((step) => step.id === currentStep)?.component;

  console.log(isSapFaultyBookingNull)

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.sap')} backTo={'/dashboard'} />
        <div className="page-content-wrapper scrollable">
          <div className="add-charging-station-page__main">
            <Card className="station-form--card">
              <div className="min-h-screen bg-gray-100 py-8">
                <div className="max-w-4xl mx-auto">
                  <div className="rounded-lg shadow-md p-6">
                    {
                      showProgressBar && <StepIndicator steps={steps} currentStep={currentStep} />
                    }

                    <div className="mb-8">
                      {CurrentStepComponent && (
                        <CurrentStepComponent
                          month={month}
                          setMonth={setMonth}
                          setShowButton={setShowButton}
                          setIsSapFaultyBookingNull={setIsSapFaultyBookingNull}
                          setShowProgressBar={setShowProgressBar}
                          setCurrentStep={setCurrentStep}
                        />
                      )}
                    </div>

                    {showButton && (
                      <div className="sap-btn--block">
                        <Button title="Previous" onClick={handlePrevious} disabled={currentStep === 0}>
                          <FiChevronLeft /> Prev
                        </Button>
                        <Button onClick={handleNext} title={'Next'} 
                        // disabled={currentStep === steps.length || !isSapFaultyBookingNull}
                        >
                          Next <FiChevronRight />
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
        <Modal show={showPasscodeModal} size="lg" onHide={() => setShowPasscodeModal(false)} centered>
          <div className="wallet-transaction_main">
            <Card>
              <div className="wallet-transaction-header__block">
                <div className="wallet-transaction-name">
                  <span>Enter Passcode</span>
                </div>
                <div className="close-btn">
                  <MdClose size={30} color="#be210b" onClick={() => setShowPasscodeModal(false)} />
                </div>
              </div>
              <div className="wallet-transaction_form">
                <Formik
                  initialValues={{ passcode: '' }}
                  validationSchema={PasscodeSchema}
                  onSubmit={(values, { setSubmitting, setFieldError }) => {
                    handlePasscodeSubmit(values.passcode, { setFieldError });
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={12}>
                          <TextInput
                            isRequired
                            placeholder="Enter passcode"
                            label="Passcode"
                            name="passcode"
                            value={values.passcode}
                            onChange={handleChange}
                            error={errors.passcode && touched.passcode ? errors.passcode : null}
                          />
                        </Col>
                      </Row>
                      <div className="wallet-transaction_button">
                        <Button className="wallet-transaction-btn cancel_btn" onClick={() => setShowPasscodeModal(false)}>
                          {t('button.cancel')}
                        </Button>
                        <Button type="submit" disabled={isSubmitting} className="create-btn wallet-transaction-btn">
                          {t('button.submit')}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
        </Modal>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default StepForm;
